.Register {
    color: white;
    height: 100vh;
    background: black;
    overflow-y: scroll;
    background-image: radial-gradient(
            circle at 40% 20%,
            rgba(255, 140, 245, 0.03) 20%,
            transparent 40%
        ),
        radial-gradient(circle at 60% 50%, rgba(61, 177, 160, 0.05) 100%, transparent 40%),
        radial-gradient(circle at 40% 60%, rgba(94, 35, 142, 0.03) 100%, transparent 40%);

    .container {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(60, 60, 60, 0.2);
        text-align: left;
        padding: 2rem;
        border-radius: 1rem;
        max-width: 600px;

        @media screen and (max-width: 600px) {
            & {
                padding: 2rem 1.5rem;
                position: static;
                top: 0;
                left: 0;
                min-height: 100vh;
                transform: none;
                border-radius: 0;
            }
        }

        .flex {
            width: 100%;
            display: flex;
            justify-content: space-around;

            span {
                font-size: 5rem;
                color: #777;
            }

            span:last-of-type {
                color: var(--success);
            }

            .logo {
                width: 5rem;
                height: 5rem;
            }

            @media screen and (max-width: 425px) {
                & {
                    margin-bottom: 2rem;
                }

                .logo,
                span {
                    width: 3rem;
                    height: 3rem;
                    font-size: 3rem;
                }
            }
        }

        h1 {
            text-align: center;
        }

        h3 {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--subtext);
            text-align: center;
        }

        @media screen and (max-width: 425px) {
            h1,
            h3 {
                font-size: 1.2rem;
            }
        }

        .text {
            color: #eee;
            font-weight: 500;
            font-size: 1rem;
            margin: 3rem 0 2rem 0;
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        label {
            font-size: 1rem;
            font-weight: 500;
            color: var(--subtext);
            display: flex;
            align-items: center;
            gap: 10px;
        }

        input {
            font-family: inherit;
            font-size: 1rem;
            width: 100%;
            padding: 10px;
            margin: 12px 0 1rem 0;
            border: none;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.04);
            border: 1.2px solid #333;
            color: white;
        }
    }
}

.RegisterField {
    padding: 1rem;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1.2px solid #333;
    margin: 12px 0 1rem 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.RegisterButton {
    font-family: inherit;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    background: var(--theme);
    border: 0;
    border-radius: 5px;
    padding: 10px 3rem;
    color: black;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
