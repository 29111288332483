.Modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(65, 65, 65, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.modalPopup {
    position: fixed;
    z-index: 1000;
    top: 30%;
    left: 50%;
    background: rgba(30, 30, 30, 0.9);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    color: white;
    width: 90%;
    max-width: 600px;
    height: fit-content;
    transition: 0.4s;
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -150%) scale(0.7);

    @media screen and (max-width: 768px) {
        & {
            top: 0%;
            left: 0%;
            border-radius: 0;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        padding: 10px 2rem 2rem 2rem;

        @media screen and (max-width: 768px) {
            & {
                padding: 10px 1rem 1rem 1rem;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 768px) {
                & {
                    display: block;
                }

                .title {
                    display: block;
                }
            }

            .close {
                font-size: 3rem;
                cursor: pointer;
            }

            .title {
                font-size: 1.25rem;
                font-weight: 500;
            }
        }
    }
}

.active {
    opacity: 1;
    pointer-events: all;
    transform: translate(-50%, -30%) scale(1);

    @media screen and (max-width: 768px) {
        & {
            transform: none;
        }
    }
}
