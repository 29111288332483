.Management {
    background-color: black;
    color: white;
    padding: 0 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;

    @media screen and (max-width: 425px) {
        & {
            padding: 0;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        user-select: none;

        @media screen and (max-width: 425px) {
            & {
                padding: 10px 10px 1rem 10px;
            }
        }

        @media screen and (max-width: 768px) {
            & {
                justify-content: center;
            }
        }

        .managing {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 1rem;
            text-decoration: none;
            color: white;

            .title {
                border-bottom: 2px solid transparent;

                &:hover {
                    color: var(--theme);
                    border-bottom: 2px solid var(--theme);
                }
            }

            @media screen and (max-width: 768px) {
                & {
                    display: none;
                }
            }
        }

        .switchPage {
            display: flex;
            align-items: center;
            gap: 10px;

            label {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 0.95rem;
                cursor: pointer;
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                font-family: inherit;
                font-size: 0.9rem;
                text-decoration: none;
                font-weight: 500;
                background: rgba(88, 88, 88, 0.5);
                border: 0;
                border-radius: 5px;
                padding: 4px 2rem 4px 4px;
                color: white;
                font-weight: 500;
                cursor: pointer;
                max-width: 200px;
                display: flex;
                align-items: center;
                gap: 10px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 1 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 0.5rem top 50%;
                background-size: 1rem auto;
            }
        }

        .toolbar {
            display: flex;
            align-items: center;
            gap: 1rem;

            .button {
                font-family: inherit;
                font-size: 0.9rem;
                text-decoration: none;
                font-weight: 500;
                background: var(--theme);
                border: 0;
                border-radius: 5px;
                padding: 8px 1rem;
                color: black;
                font-weight: 500;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                transition: 0.2s;

                &:hover {
                    filter: brightness(0.85);
                }
            }

            @media screen and (max-width: 1168px) {
                & {
                    display: none;
                }
            }
        }
    }

    .notice {
        background: #ffc849;
        color: black;
        border-radius: 5px 5px 0 0;
        padding: 4px;
        text-align: center;
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: 425px) {
            & {
                border-radius: 0;
            }
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        display: block;
        border: none;
        border-radius: 0 0 5px 5px;
        border-left: 2px solid #ffc849;
        border-right: 2px solid #ffc849;
        border-bottom: 2px solid #ffc849;

        @media screen and (max-width: 425px) {
            & {
                border: 0;
            }
        }
    }

    .iframeLoader {
        width: 100%;
        height: 100%;
        background: #111;
        background-image: radial-gradient(
                circle at 40% 20%,
                rgba(255, 140, 245, 0.03) 20%,
                transparent 40%
            ),
            radial-gradient(circle at 60% 50%, rgba(61, 177, 160, 0.05) 100%, transparent 40%),
            radial-gradient(circle at 40% 60%, rgba(35, 44, 142, 0.1) 20%, transparent 80%);
        border: none;
        border-radius: 0 0 5px 5px;
        font-size: 2rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 425px) {
            & {
                font-size: 1.5rem;
                flex-direction: column;
            }
        }

        .loader {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            border: 3px solid var(--theme);
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;

            @keyframes rotation {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}

.modal {
    display: flex;
    gap: 1rem;
    padding-top: 1rem;

    @media screen and (max-width: 768px) {
        & {
            flex-direction: column;
        }
    }

    ul {
        li {
            margin-bottom: 2rem;
            line-height: 1.4;
        }
    }

    .modalInput {
        font-family: inherit;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border: 1px solid #444;
        border-radius: 5px;
        background: #222;
        color: white;
        font-weight: 500;
        outline: none;
        width: 100%;
        transition: 0.2s;
    }

    .modalButton {
        font-family: inherit;
        font-size: 1rem;
        text-decoration: none;
        text-align: center;
        font-weight: 500;
        background: var(--theme);
        border: 0;
        border-radius: 5px;
        padding: 10px 3rem;
        color: black;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}
