.Login {
    color: white;
    height: 100vh;
    background: black;
    overflow-y: scroll;
    background-image: radial-gradient(
            circle at 40% 20%,
            rgba(255, 140, 245, 0.03) 20%,
            transparent 40%
        ),
        radial-gradient(circle at 60% 50%, rgba(61, 177, 160, 0.05) 100%, transparent 40%),
        radial-gradient(circle at 40% 60%, rgba(94, 35, 142, 0.03) 100%, transparent 40%);

    .container {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(60, 60, 60, 0.2);
        text-align: left;
        padding: 2rem;
        border-radius: 1rem;
        max-width: 600px;

        @media screen and (max-width: 600px) {
            & {
                padding: 2rem 1.5rem;
                position: static;
                top: 0;
                left: 0;
                min-height: 100vh;
                transform: none;
                border-radius: 0;
            }
        }

        .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo {
                width: 6rem;
                height: 6rem;

                @media screen and (max-width: 425px) {
                    & {
                        width: 3rem;
                        height: 3rem;
                    }
                }
            }

            h1 {
                margin: 0;
                text-align: right;
            }

            h3 {
                margin: 0;
                font-size: 1.5rem;
                font-weight: 400;
                text-align: right;
                color: var(--subtext);
            }

            @media screen and (max-width: 425px) {
                h1,
                h3 {
                    font-size: 1.2rem;
                }
            }
        }

        .text {
            color: #eee;
            font-weight: 500;
            font-size: 1rem;
            margin: 3rem 0 2rem 0;
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        label {
            font-size: 1rem;
            font-weight: 500;
            color: var(--subtext);
            display: flex;
            align-items: center;
            gap: 10px;
        }

        input {
            font-family: inherit;
            font-size: 1rem;
            width: 100%;
            padding: 10px;
            margin: 12px 0 1rem 0;
            border: none;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.04);
            border: 1.2px solid #333;
            color: white;
        }

        .notice {
            padding: 1rem;
            margin: 0;
            border-radius: 10px;
            font-size: 0.9rem;
            color: var(--subtext);
            background-color: rgba(255, 255, 255, 0.05);
        }

        .org {
            display: flex;
            gap: 1rem;
            color: (--subtext);
            border-radius: 8px;
            margin-top: 3rem;
            margin-bottom: -1rem;
            align-items: center;

            span {
                font-size: 1.8rem;
            }
        }
    }
}
